export const companyFeatureFlags = {
    ENERGY: 'Energy',
    SHOP_YARD: 'Shop/Yard',
};

// these defaults are also in \App\Model\Company\CompanyFeatureFlags
export const companyFeatureFlagsDefaults = {
    ENERGY: false,
    SHOP_YARD: false,
};
